const targetEnvironment = process.env.REACT_APP_ENV;
// const targetEnvironment = 'production';
const appVersion = '1.4.7';

// endpoint config
const serverUrl = {
	localMamp: 'http://localhost:8888',
	beta: 'http://139.59.229.242:8080',
	production: 'http://188.166.249.144:8080',
	aorsormor: 'http://167.99.73.11:8080',
};

const appPath = {
	localMamp: '',
	beta: '',
	production: '',
	aorsormor: '',
};

const AppConfig = {
	appVersion: appVersion,
	targetEnvironment: targetEnvironment,
	appPath: appPath[targetEnvironment],
	localStorageKey: 'esky' + targetEnvironment,
	serverUrl: serverUrl[targetEnvironment] + '/',
	apiUrl: serverUrl[targetEnvironment] + '/api/',
	tableRowsPerPage: 20,
	timeToInvalidateCacheInMilliSecond: 60 * 60 * 12 * 1000,
	startFilterYear: 2019,
	restaurantCommentPin: 5,
};

export default AppConfig;
