import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import Select from 'react-select';
import { toast } from 'react-toastify';

import NumberColor from '../../../Components/NumberColor';
import NumberString from '../../../Components/NumberString';
import FileManager from '../../../Components/FileManager';
import LoadingCenter from '../../../Components/LoadingCenter';
import DateLabel from '../../../Components/DateLabel';

import APIPut from '../../../API/APIPut';
import APIPost from '../../../API/APIPost';
import APIDelete from '../../../API/APIDelete';
import APIHelper from '../../../Helper/APIHelper';
import OpenLink from '../../../Helper/OpenLink';
import AdminLockRender from '../../../Helper/AdminLockRender';

import PROGRAM_STATUS from '../../../StaticData/ProgramStatus';

import APIGet from '../../../API/APIGet';
import { SelectPlan } from './SelectPlan';

export default class Summary extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			isLoadingStaff: false,
			status: {
				value: props.program.status,
				label: props.program.status,
			},
			staff: null,
		};
	}

	handleInputChange = (name, value) => {
		this.setState({
			[name]: value,
		});
	};

	handleStatusChange = async value => {
		const oldStatus = this.state.status;
		this.setState({
			status: value,
			isLoading: true,
		});

		const data = {
			status: value.value,
		};

		const { program } = this.props;
		const request = await APIPut('incentive/programs/' + program.id + '/status', data);
		if (APIHelper.handleAPIResponse(this, request)) {
			const program = request.data;
			console.log('program: ', program);
			this.setState({
				isLoading: false,
				program,
			});

			toast.success('แก้ไขสถานะเรียบร้อยแล้ว');
			this.props.onProgramUpdated(program);
		} else {
			toast.error('เกิดปัญหาในการแก้ไขสถานะ');
			this.setState({
				status: oldStatus,
			});
		}
	};

	onUploadSuccess = program => {
		toast.success('Upload เรียบร้อยแล้ว');
		this.props.onProgramUpdated(program);
	};

	onUploadFail = () => {
		toast.error('เกิดปัญหาในการ Upload');
	};

	onDeleteSuccess = program => {
		toast.success('ลบเรียบร้อยแล้ว');
		this.props.onProgramUpdated(program);
	};

	onDeleteFail = () => {
		toast.error('เกิดปัญหาในการลบ');
	};

	addStaff = async () => {
		if (this.state.staff) {
			const { program } = this.props;
			this.setState({
				isLoadingStaff: true,
			});

			const request = await APIPost('incentive/programs/' + program.id + '/staffs', {
				staffId: this.state.staff.value,
			});

			if (APIHelper.handleAPIResponse(this, request)) {
				const incentiveProgramStaffs = request.data;
				console.log('incentiveProgramStaffs: ', incentiveProgramStaffs);
				this.setState({
					isLoadingStaff: false,
				});

				toast.success('เพิ่ม Staff เรียบร้อยแล้ว');
				let updatedProgram = JSON.parse(JSON.stringify(program));
				updatedProgram.incentiveProgramStaffs = incentiveProgramStaffs;
				this.props.onProgramUpdated(updatedProgram);
			} else {
				this.setState({
					isLoadingStaff: false,
				});

				toast.error('เกิดปัญหาในการเพิ่ม Staff');
			}
		} else {
			toast.warn('กรุณาเลือก Staff');
		}
	};

	removeStaff = async staffId => {
		const { program } = this.props;
		this.setState({
			isLoadingStaff: true,
		});

		const request = await APIDelete('incentive/programs/' + program.id + '/staffs/' + staffId);
		if (APIHelper.handleAPIResponse(this, request)) {
			this.setState({
				isLoadingStaff: false,
			});

			toast.success('ลบ Staff เรียบร้อยแล้ว');
			let updatedProgram = JSON.parse(JSON.stringify(program));
			updatedProgram.incentiveProgramStaffs = updatedProgram.incentiveProgramStaffs.filter(staff => staff.staffId !== staffId);
			this.props.onProgramUpdated(updatedProgram);
		} else {
			this.setState({
				isLoadingStaff: false,
			});

			toast.error('เกิดปัญหาในการลบ Staff');
		}
	};

	savePlanning = async selectedPlanId => {
		this.setState({
			isLoading: true,
		});

		const { program } = this.props;
		const data = {
			...program,
			incentivePlanId: selectedPlanId,
		};

		const request = await APIPut('incentive/programs/' + program.id, data);
		if (APIHelper.handleAPIResponse(this, request)) {
			const program = request.data;
			console.log('program: ', program);
			this.setState({
				isLoading: false,
				program,
			});

			toast.success('แก้ไข Plan เรียบร้อย');
			this.props.onProgramUpdated(program);
		} else {
			toast.error('เกิดปัญหาในการแก้ไข');
		}
	};

	searchFromFilter = async filter => {
		// if (filter.name) body.name = filter.name;
		// console.log('search request', body);

		let response = await APIGet('incentivePlan', {});

		if (APIHelper.handleAPIResponse(this, response)) {
			// console.log('search response', response.data);

			this.setState({
				isLoading: false,
				plans: [...response.data],
			});
		} else {
			toast.error('ไม่สามารถดึงข้อมูลโปรแกรมได้ กรุณาลองใหม่อีกครั้ง');
			this.setState({
				isLoading: false,
			});
		}
	};

	render() {
		const { program, incomes, incomeEtcs, expenseGroup } = this.props;
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const incomeFromCustomer = (incomes != null ? incomes : []).reduce((sum, income) => sum + income.incomeFromCustomer * income.currencyRate, 0);
		const toCollectFromCustomer = (incomes != null ? incomes : []).reduce((sum, income) => sum + income.toCollectFromCustomer * income.currencyRate, 0);
		const incomeEtc = (incomeEtcs != null ? incomeEtcs : []).reduce((sum, incomeEtc) => sum + incomeEtc.total * incomeEtc.currencyRate, 0);
		const expense = (expenseGroup != null ? expenseGroup : []).reduce((sum, expense) => sum + expense.totalApprovedAndFinanceReviewInThb, 0);
		return (
			<div className="columns">
				<div className="column is-5">
					<div className="columns">
						<div className="column is-12">
							ราคา: <NumberColor number={program.total * program.currencyRate} /> บาท <br />
							ลูกค้า: <strong>{program.company ? program.company.name : ''}</strong> <br />
							จำนวนลูกค้า:{' '}
							<strong>
								<NumberColor number={program.numberOfCustomers} /> ท่าน
							</strong>
							<hr />
							ผู้ติดต่อ: <strong>{program.companyUser.name}</strong> <br />
							เบอร์โทร: <strong>{program.companyUser.telephone}</strong> <br />
							email: <strong>{program.companyUser.email}</strong> <br />
							Line: <strong>{program.companyUser.lineId}</strong> <br />
							<hr />
							{this.state.isLoadingStaff ? (
								<LoadingCenter />
							) : (
								<div className="row">
									<label className="label">พนักงาน:</label>
									<div className="content">
										<ul>
											{program.incentiveProgramStaffs.map(incentiveProgramStaff => {
												const staff = incentiveProgramStaff.staff;
												if (!staff) {
													return null;
												}

												return (
													<li key={'staff_' + staff.id}>
														{staff.name} ({staff.nickname} - {staff.role}){' '}
														<span
															className="clickable"
															style={{
																color: 'red',
															}}
															onClick={() => this.removeStaff(staff.id)}
														>
															(ลบ)
														</span>
													</li>
												);
											})}
										</ul>
									</div>
									<div className="columns">
										<div className="column is-9">
											{AdminLockRender(
												program.status,
												<Select
													name="staff"
													value={this.state.staff}
													clearable={false}
													onChange={value => this.handleInputChange('staff', value)}
													options={this.props.staffs}
												/>,
												<Select
													name="staff"
													value={this.state.staff}
													clearable={false}
													onChange={value => this.handleInputChange('staff', value)}
													options={this.props.staffs}
													isDisabled={true}
												/>
											)}
										</div>
										<div className="column is-3">
											{AdminLockRender(
												program.status,
												<button className="button" onClick={this.addStaff}>
													เพิ่ม
												</button>,
												<button className="button" disabled>
													เพิ่ม
												</button>
											)}
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
					{AdminLockRender(
						program.status,
						<FileManager
							title="Files"
							files={program.incentiveProgramFiles}
							api={'incentive/programs/' + program.id + '/files'}
							onUploadSuccess={this.onUploadSuccess}
							onUploadFail={this.onUploadFail}
							onDeleteSuccess={this.onDeleteSuccess}
							onDeleteFail={this.onDeleteFail}
						/>,
						<FileManager
							title="Files"
							files={program.incentiveProgramFiles}
							api={'incentive/programs/' + program.id + '/files'}
							disableUpload={true}
							disableDelete={true}
							onUploadSuccess={this.onUploadSuccess}
							onUploadFail={this.onUploadFail}
							onDeleteSuccess={this.onDeleteSuccess}
							onDeleteFail={this.onDeleteFail}
						/>
					)}
				</div>
				<div className="column is-7">
					<h3 className="subtitle is-5">Planning</h3>
					<SelectPlan program={program} onUpdatePlan={this.savePlanning} />
					<div className="columns">
						<div className="column is-4">
							<div className="box" style={{ textAlign: 'right' }}>
								รายรับที่แพลนไว้ (บาท)
								<p className="title is-4">
									<NumberColor
										number={
											program.incentivePlan && program.incentivePlan.incentiveSubPlan
												? program.incentivePlan.incentiveSubPlan.totalIncome
												: 0
										}
										isExpense={false}
									/>
								</p>
							</div>
						</div>
						<div className="column is-4">
							<div className="box" style={{ textAlign: 'right' }}>
								รายจ่ายที่แพลนไว้ (บาท)
								<p className="title is-4">
									<NumberColor
										number={
											program.incentivePlan && program.incentivePlan.incentiveSubPlan
												? program.incentivePlan.incentiveSubPlan.totalExpense
												: 0
										}
										isExpense={true}
									/>
								</p>
							</div>
						</div>
						<div className="column is-4">
							<div className="box" style={{ textAlign: 'right' }}>
								กำไรที่แพลนไว้ (บาท)
								<p className="title is-4">
									<NumberColor
										number={
											program.incentivePlan && program.incentivePlan.incentiveSubPlan
												? program.incentivePlan.incentiveSubPlan.totalProfit
												: 0
										}
										isExpense={false}
									/>
								</p>
							</div>
						</div>
					</div>
					<div className="columns">
						<div className="column is-4">
							<div className="box" style={{ textAlign: 'right' }}>
								รับชําระมาเเล้ว (บาท)
								<p className="title is-4">
									<NumberColor number={incomeFromCustomer} isExpense={false} />
								</p>
							</div>
						</div>
						<div className="column is-4">
							<div className="box" style={{ textAlign: 'right' }}>
								รายจ่ายหักลบเงินคืน (บาท)
								<p className="title is-4">
									<NumberColor number={expense - incomeEtc} isExpense={true} />
								</p>
							</div>
						</div>
						<div className="column is-4">
							<div className="box" style={{ textAlign: 'right' }}>
								กำไร (บาท)
								<p className="title is-4">
									<NumberColor number={incomeFromCustomer + incomeEtc - expense} />
								</p>
							</div>
						</div>
					</div>
					<div className="columns">
						<div className="column is-4">
							<div className="box" style={{ textAlign: 'right' }}>
								ยอดเรียกเก็บทั้งหมด (บาท)
								<p className="title is-4">
									<NumberColor number={toCollectFromCustomer} />
								</p>
							</div>
						</div>
						<div className="column is-4">
							<div className="box" style={{ textAlign: 'right' }}>
								ค้างจ่าย (บาท)
								<p className="title is-4">
									<NumberColor number={toCollectFromCustomer - incomeFromCustomer} isExpense={true} />
								</p>
							</div>
						</div>
						<div className="column is-4">
							<div className="box" style={{ textAlign: 'right' }}>
								ส่วนต่างกำไร (บาท)
								<p className="title is-4">
									<NumberColor number={incomeFromCustomer + incomeEtc - expense} />
								</p>
							</div>
						</div>
					</div>
					<div className="columns">
						<div className="column is-4">
							<div className="box" style={{ textAlign: 'right' }}>
								<label className="label">สถานะ</label>
								{AdminLockRender(
									program.status,
									<Select
										name="status"
										value={this.state.status}
										clearable={false}
										onChange={this.handleStatusChange}
										options={PROGRAM_STATUS}
									/>,
									<Select
										name="status"
										value={this.state.status}
										clearable={false}
										onChange={this.handleStatusChange}
										options={PROGRAM_STATUS}
										isDisabled={true}
									/>
								)}
							</div>
						</div>
					</div>
					<div className="columns">
						<div className="column is-12">
							<strong>Note:</strong>
							<p>{program.note}</p>
						</div>
					</div>
					<div className="columns">
						<div className="column is-12">
							<div className="content">
								<strong>Quotation</strong>
								<ul>
									{program.pdfIncentiveProgramQuotations &&
										program.pdfIncentiveProgramQuotations.map(doc => (
											<li key={'quotation' + doc.id}>
												<a onClick={() => OpenLink.incentiveQuotation(doc.id)} target="_blank">
													{doc.documentNumber} {doc.version > 0 ? `revise ${doc.version}` : null}{' '}
												</a>
												{doc.deleted_at && (
													<label className="tag is-warning">
														Inactive - <DateLabel date={doc.deleted_at} />
														{}
													</label>
												)}
											</li>
										))}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
