import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import DateLabel from '../../../Components/DateLabel';
import NumberColor from '../../../Components/NumberColor';

import AppConfig from '../../../AppConfig';

export default class IncomeCustomer extends PureComponent {
	render() {
		const { program } = this.props;
		return (
			<div className="section">
				<div className="columns">
					<div className="column is-6">
						<h3 className="title is-3">รายรับ/การเก็บเงินจากลูกค้า </h3>
					</div>
					<div className="column is-6" style={{ textAlign: 'right' }} />
				</div>
				<div className="table-container">
					<table className="table is-hoverable is-fullwidth is-striped is-bordered">
						<thead>
							<tr className="table-header">
								<td width="40%">รายการ</td>
								<td width="20%">ชำระภายใน</td>
								<td width="20%">ยอดเรียกเก็บทั้งหมด (บาท)</td>
								<td width="20%">รับชำระมาแล้ว (บาท)</td>
							</tr>
						</thead>
						<tbody>
							{program.deposit > 0 ? (
								<tr>
									<td>
										<Link to={AppConfig.appPath + '/programs/jointour/' + this.props.programId + '/incomes/deposit'}>
											{program.deposit > program.paidDeposit ? (
												<label className="tag is-warning">ยังเก็บไม่ครบ</label>
											) : (
												<label className="tag is-success">เก็บเงินแล้ว</label>
											)}{' '}
											มัดจำ
										</Link>
									</td>
									<td style={{ textAlign: 'center' }}>
										<DateLabel date={program.dueDateDeposit} />
									</td>
									<td className="number-cell">
										<NumberColor number={program.deposit} />
									</td>
									<td className="number-cell">
										<NumberColor number={program.paidDeposit} />
									</td>
								</tr>
							) : null}
							{program.deposit2 > 0 ? (
								<tr>
									<td>
										<Link to={AppConfig.appPath + '/programs/jointour/' + this.props.programId + '/incomes/deposit2'}>
											{program.deposit2 > program.paidDeposit2 ? (
												<label className="tag is-warning">ยังเก็บไม่ครบ</label>
											) : (
												<label className="tag is-success">เก็บเงินแล้ว</label>
											)}{' '}
											มัดจำ (งวดที่ 2)
										</Link>
									</td>
									<td style={{ textAlign: 'center' }}>
										<DateLabel date={program.dueDateDeposit2} />
									</td>
									<td className="number-cell">
										<NumberColor number={program.deposit2} />
									</td>
									<td className="number-cell">
										<NumberColor number={program.paidDeposit2} />
									</td>
								</tr>
							) : null}
							<tr>
								<td>
									<Link to={AppConfig.appPath + '/programs/jointour/' + this.props.programId + '/incomes/full'}>
										{program.full > program.paidFull ? (
											<label className="tag is-warning">ยังเก็บไม่ครบ</label>
										) : (
											<label className="tag is-success">เก็บเงินแล้ว</label>
										)}{' '}
										เต็มจำนวน
									</Link>
								</td>
								<td style={{ textAlign: 'center' }}>
									<DateLabel date={program.dueDateFull} />
								</td>
								<td className="number-cell">
									<NumberColor number={program.full} />
								</td>
								<td className="number-cell">
									<NumberColor number={program.paidFull} />
								</td>
							</tr>
							<tr className="table-summary-row">
								<td colSpan="2">Summary</td>
								<td className="number-cell">
									<NumberColor number={program.price} />
								</td>
								<td className="number-cell">
									<NumberColor number={program.paid} />
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
