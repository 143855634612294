import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import NumberColor from '../../../Components/NumberColor';
import DateLabel from '../../../Components/DateLabel';

import AuthHelper from '../../../Helper/AuthHelper';
import AdminLockRender from '../../../Helper/AdminLockRender';

import AppConfig from '../../../AppConfig';

export default class Income extends PureComponent {
	render() {
		const { program, incomeEtcs } = this.props;
		let total = 0;
		return (
			<div className="section">
				<div className="columns">
					<div className="column is-6">
						<h3 className="title is-3">รายรับอื่นๆ</h3>
					</div>
					<div className="column is-6" style={{ textAlign: 'right' }}>
						{AdminLockRender(
							program.status,
							AuthHelper.hasRight('Finance', 'Admin') ? (
								<Link className="button is-success" to={AppConfig.appPath + '/programs/incentive/' + this.props.programId + '/newincomeetc'}>
									เพิ่มรายการ
								</Link>
							) : null,
							AuthHelper.hasRight('Finance', 'Admin') ? (
								<button className="button is-success" disabled>
									เพิ่มรายการ
								</button>
							) : null
						)}

						<span> </span>
						{/* <button className="button is-info">PDF</button> */}
					</div>
				</div>
				<div className="table-container">
					<table className="table is-hoverable is-fullwidth is-striped is-bordered">
						<thead>
							<tr className="table-header">
								<td width="30%">รายการ</td>
								<td width="15%">ประเภท</td>
								<td width="15%">วันที่</td>
								<td>ราคา (บาท)</td>
							</tr>
						</thead>
						<tbody>
							{incomeEtcs.map(income => {
								total += income.total * income.currencyRate;
								return (
									<tr key={'income' + income.id}>
										<td>
											<Link to={AppConfig.appPath + '/programs/incentive/' + this.props.programId + '/incomeEtcs/' + income.id}>
												{income.title}
											</Link>
										</td>
										<td>{income.incentiveIncomeEtcCategory.name}</td>
										<td>
											<DateLabel date={income.paymentDate} />
										</td>
										<td className="number-cell">
											{!income.financeStaff ? <span className="tag is-warning">บัญชียังไม่ตรวจสอบ</span> : null}
											<span> </span>
											<NumberColor number={income.total * income.currencyRate} />
										</td>
									</tr>
								);
							})}
							<tr className="table-summary-row">
								<td colSpan="3">Summary</td>
								<td className="number-cell">
									<NumberColor number={total} />
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
